export enum Method {
  Get = 'get',
  Post = 'post',
  Patch = 'patch',
  Delete = 'delete',
}

export interface Route {
  path: string
  method: Method
  data?: any
  params?: any
  auth?: boolean
  file?: File
  extern?: boolean
  skipHumps?: boolean
  headers?: any
}

export const BASE_URL = 'https://api.gymspot.fr'

const ROUTES: { [name: string]: (payload?: any) => Route } = {
  CONTACT: (data: any) => ({
    path: `/contact`,
    method: Method.Post,
    auth: false,
    data,
  }),
}

export default ROUTES
