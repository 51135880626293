import { h } from 'preact'
import Logo from '../../../assets/images/logo.svg'
import IconHamburgerMenu from '../../../assets/images/icons/icon_hamburger_menu.svg'
import './Header.scss'
import { useState } from 'preact/hooks'
import { route } from 'preact-router'

export default () => {
  const [hamburgerMenuExpanded, setHamburgerMenuExpanded] = useState(false)

  const toggleHamburgerMenuExpanded = () => {
    setHamburgerMenuExpanded((prev) => !prev)
  }

  return (
    <div class={`Header ${hamburgerMenuExpanded ? 'Header--expanded' : ''}`}>
      {/* BEGIN -- Hamburger menu -- mobile -- BEGIN*/}
      <div class="Header__hamburgerMenu">
        <IconHamburgerMenu onClick={toggleHamburgerMenuExpanded} />
        {hamburgerMenuExpanded && (
          <div class="Header__hamburgerMenuContentWrapper">
            <div class="Header__hamburgerMenuContent">
              <a
                href="/#solutions"
                class="Header__entry Header__entry--mobile"
                onClick={() => setHamburgerMenuExpanded(false)}
              >
                Nos solutions
              </a>
              <a
                href="#company"
                class="Header__entry Header__entry--mobile Header__categoriesButton"
                onClick={() => setHamburgerMenuExpanded(false)}
              >
                Le sport en entreprise
              </a>
            </div>
          </div>
        )}
      </div>
      {/* END -- Hamburger menu -- mobile -- END */}
      <div class="Header__left">
        <div
          class="Header__logo"
          onClick={() => {
            route('/')
            window.location.reload()
          }}
        >
          <Logo />
        </div>
      </div>
      <div class="Header__right">
        <div class="Header__entry">
          <a href="/#solutions">Nos solutions</a>
        </div>
        <div class="Header__entry">
          <a href="/#company">Le sport en entreprise</a>
        </div>
      </div>
    </div>
  )
}
