import { h } from 'preact'
import Logo from '../../../assets/images/logo_white.png'
import IconFacebook from '../../../assets/images/footer_facebook.svg'
import IconInstagram from '../../../assets/images/footer_instagram.svg'
import IconLinkedin from '../../../assets/images/footer_linkedin.svg'
import IconRecruitment from '../../../assets/images/icons/icon_recruitment.svg'
import IconMail from '../../../assets/images/icon_mail.svg'
import IconPhone from '../../../assets/images/icon_mobile.svg'
import './Footer.scss'

export default () => {
  return (
    <div class="Footer">
      <div class="Footer__content">
        <div class="Footer__logo Footer__section">
          <img src={Logo} />
        </div>
        <div class="Footer__contact Footer__section">
          <div class="Footer__title">Contact Gymspot</div>
          <div>1, place Paul Verlaine</div>
          <div>92100 Boulogne-Billancourt</div>
          <div class="Footer__highlight">
            <a href="mailto:contact@gymspot.fr" target="_blank">
              <IconMail />
              michaelcentesimo@gymspot.fr
            </a>
          </div>
          <div class="Footer__highlight Footer__highlightPhone">
            <IconPhone />
            07 77 91 57 06
          </div>
        </div>
        <div class="Footer__links Footer__section">
          <div class="Footer__title">Liens utiles</div>
          <div>
            <a href="/tos#privacy">Politique de confidentialité</a>
          </div>
          <div>
            <a href="/legal_notice">Mentions légales</a>
          </div>
          <a href="https://gymspot.fr/coach_signup">
            <div class="Footer__button Footer__recruitment">
              Recrutement Coach Sportif
              <IconRecruitment />
            </div>
          </a>
        </div>
        <div class="Footer__socialNetworks Footer__section">
          <div class="Footer__title">Suivez-nous</div>
          <div class="Footer__socialNetworksIcons">
            <a href="https://www.facebook.com/Gymspotfr/" target="_blank">
              <IconFacebook />
            </a>
            <a
              href="https://www.instagram.com/gymspotfr/?hl=fr"
              target="_blank"
            >
              <IconInstagram />
            </a>
            <a
              href="https://www.linkedin.com/company/gymspot-fr/"
              target="_blank"
            >
              <IconLinkedin />
            </a>
          </div>
        </div>
      </div>
      <div class="Footer__copyright">&copy; 2021 Gymspot</div>
    </div>
  )
}
