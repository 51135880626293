import { h } from 'preact'
import store, { Message } from './services/store'
import Layout from './Layout'
import Toastify from 'toastify-js'

export default () => {
  store.listen(Message.Error, (msg) =>
    Toastify({
      text: msg,
      duration: 3000,
      stopOnFocus: true,
      backgroundColor: ' #fc7169',
    }).showToast()
  )
  store.listen(Message.Notification, (msg) =>
    Toastify({
      text: msg,
      duration: 3000,
      stopOnFocus: true,
      backgroundColor: '#4dce76',
    }).showToast()
  )

  return <Layout />
}
