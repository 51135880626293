import { h } from 'preact'
import { useState } from 'preact/hooks'
import Button from '../../components/Button'
import InputWithLabel from '../../components/InputWithLabel'
import ROUTES from '../../routes'
import httpClient from '../../services/httpClient'
import './ContactForm.scss'

interface Props {
  onDismiss: () => void
}

export default (props: Props) => {
  const [contactData, setContactData] = useState<any>({})

  const canSendMessage = () => {
    return (
      contactData.lastName &&
      contactData.lastName.length > 0 &&
      contactData.firstName &&
      contactData.firstName.length > 0 &&
      contactData.email &&
      contactData.email.length > 0 &&
      contactData.phone &&
      contactData.phone.length > 0 &&
      contactData.company &&
      contactData.company.length > 0 &&
      contactData.message &&
      contactData.message.length > 0
    )
  }

  const sendMessage = async () => {
    props.onDismiss()
    await httpClient.req(ROUTES.CONTACT(contactData))
  }

  return (
    <div className="ContactForm" onClick={props.onDismiss}>
      <i className="ContactForm__dismiss" onClick={props.onDismiss}>
        close
      </i>
      <div
        className="ContactForm__content"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="ContactForm__contentTitle">Nous contacter</div>
        <div className="ContactForm__contentBody">
          <InputWithLabel
            label="Nom"
            onChange={(v) => setContactData({ ...contactData, lastName: v })}
          />
          <InputWithLabel
            label="Prénom"
            onChange={(v) => setContactData({ ...contactData, firstName: v })}
          />
          <InputWithLabel
            label="Email"
            onChange={(v) => setContactData({ ...contactData, email: v })}
          />
          <InputWithLabel
            label="Téléphone"
            onChange={(v) => setContactData({ ...contactData, phone: v })}
          />
          <InputWithLabel
            label="Société"
            onChange={(v) => setContactData({ ...contactData, company: v })}
          />
          <InputWithLabel
            label="Message"
            textarea
            onChange={(v) => setContactData({ ...contactData, message: v })}
          />
          <Button disabled={!canSendMessage()} onClick={sendMessage}>
            Envoyer
          </Button>
        </div>
      </div>
    </div>
  )
}
