import { h } from 'preact'
import './Home.scss'
import Woman from '../../assets/images/woman.png'
import Solution1 from '../../assets/images/solution_1.png'
import Solution2 from '../../assets/images/solution_2.png'
import Solution3 from '../../assets/images/solution_3.png'
import Company1 from '../../assets/images/company_1.svg'
import Company2 from '../../assets/images/company_2.svg'
import Company3 from '../../assets/images/company_3.svg'
import Solution3Bis from '../../assets/images/solution_3_bis.png'
import Solution4 from '../../assets/images/solution_4.png'
import IconCardio from '../../assets/images/icon_cardio.svg'
import IconShoe from '../../assets/images/icon_shoe.svg'
import IconHands from '../../assets/images/icon_hands.svg'
import IconPilates from '../../assets/images/icon_pilates.svg'
import IconDownload from '../../assets/images/icon_download.svg'
import { Parallax } from 'react-scroll-parallax'
import { useEffect, useState } from 'preact/hooks'
import { RoutableProps } from 'preact-router'
import ContactForm from './ContactForm'

export default (_: RoutableProps) => {
  const [showContactForm, setShowContactForm] = useState(false)

  useEffect(() => {
    updateDimensions()
    window.addEventListener('resize', updateDimensions)
  }, [])

  const updateDimensions = () => {
    let elem = document.querySelector('.Home__search') as HTMLElement
    let body = document.querySelector('body') as HTMLElement

    elem.style.height = window.innerHeight.toString()
    elem.style.width = body.clientWidth.toString()
  }

  return (
    <article class="Home">
      {showContactForm && (
        <ContactForm onDismiss={() => setShowContactForm(false)} />
      )}
      <div class="Home__content">
        <section class="Home__search">
          <h1 style={{ opacity: 0, position: 'absolute', top: '-1000px' }}>
            Activité sport et bien-être
          </h1>
          <div class="Home__searchText">
            <Parallax className="Home__searchTextFirst" y={[-35, 35]}>
              <div className="Home__searchTextFirstLine">Activités</div>
              <div className="Home__searchTextSecondLine">SPORT</div>
            </Parallax>
            <Parallax className="Home__searchImage" y={[-0, 0]}>
              <img src={Woman}></img>
            </Parallax>
            <Parallax className="Home__searchTextSecond" y={[-35, 35]}>
              <div className="Home__searchTextFirstLine">et</div>
              <div className="Home__searchTextSecondLine">BIEN-ÊTRE</div>
            </Parallax>
          </div>
        </section>
        <section class="Home__description">
          <h2 class="Home__descriptionTitle">
            <span>Améliorez la qualité de vie au travail</span> de vos
            collaborateurs, <span>donnez du boost à votre entreprise.</span>
          </h2>
        </section>
        <section class="Home__contact">
          <div class="Home__contactContent">
            <div class="Home__contactText">
              <div class="Home__contactTitle">
                Vous êtes une entreprise et souhaitez en savoir plus ?
              </div>
              <div class="Home__contactSubtitle">
                (Nous vous rappelons dans les 24h)
              </div>
            </div>
            <div
              class="Home__contactButton"
              onClick={() => setShowContactForm(true)}
            >
              Contactez-nous
            </div>
          </div>
        </section>
        <section class="Home__solutions" id="solutions">
          <div class="Home__solutionsTitle">NOS SOLUTIONS</div>
          <div class="Home__solutionsContent">
            <div class="Home__solutionsContentEntry">
              <img
                src={Solution1}
                class="Home__solutionsContentEntryImage Home__solutionsContentEntryImage--left"
              />
              <div class="Home__solutionsContentEntryText">
                <IconCardio class="Home__solutionsContentEntryTextIcon" />
                <div class="Home__solutionsContentEntryTextTitle">
                  Cours Sport & bien-être
                </div>
                <div class="Home__solutionsContentEntryTextBody">
                  Un large panel d’activités dispensées par des coachs experts,
                  pour améliorer le quotidien de vos collaborateurs sur leur
                  lieu de travail. Du cours cardio et de renforcement
                  muscuclaire à la gym douce ou le Yoga pour un bien être
                  physique et mental. Il y en a pour tous les goûts, afin de
                  personnaliser l’offre en fonction des envies et besoins de vos
                  salariés.
                </div>
              </div>
            </div>
            <div class="Home__solutionsContentEntry">
              <img
                src={Solution2}
                class="Home__solutionsContentEntryImage Home__solutionsContentEntryImage--right"
              />
              <div class="Home__solutionsContentEntryText">
                <IconShoe class="Home__solutionsContentEntryTextIcon" />
                <div class="Home__solutionsContentEntryTextTitle">
                  Prévention santé & activités physiques adaptées
                </div>
                <div class="Home__solutionsContentEntryTextBody">
                  L’unité et l’inclusion par le sport. Nous promouvons le sport
                  comme le meilleur remède. Nos coachs experts santé encadrent
                  vos effectifs dans l’objectif de vous prévenir des risques
                  liés à l’activité professionnelle. Les personnes en situation
                  de handicap bénéficient de l’expertise des intervenants en
                  activités physique adaptées.
                </div>
              </div>
            </div>
            <div class="Home__solutionsContentEntry Home__solutionsContentEntry--centered">
              <img
                src={Solution3}
                class="Home__solutionsContentEntryImage Home__solutionsContentEntryImage--left Home__solutionsContentEntryImage--leftShifted"
              />
              <div class="Home__solutionsContentEntryText">
                <IconHands class="Home__solutionsContentEntryTextIcon" />
                <div class="Home__solutionsContentEntryTextTitle">
                  Evenement, conférence, team building
                </div>
                <div class="Home__solutionsContentEntryTextBody">
                  Un large panel d’activités dispensé par des coachs experts,
                  pour améliorer le quotidien de vos collaborateurs sur leur
                  lieu de travail. Du cours cardio ou de renforcement
                  muscuclaire à la gym douce ou le Yoga pour un bien être
                  physique et mental. Il y en a pour tous les goûts, afin de
                  personnaliser l’offre en fonction des envies et besoins de vos
                  salariés.
                </div>
              </div>
              <img
                src={Solution3Bis}
                class="Home__solutionsContentEntryImage Home__solutionsContentEntryImage--right Home__solutionsContentEntryImage--topShifted"
              />
            </div>
            <div class="Home__solutionsContentEntry">
              <img
                src={Solution4}
                class="Home__solutionsContentEntryImage Home__solutionsContentEntryImage--left"
              />
              <div class="Home__solutionsContentEntryText">
                <IconPilates class="Home__solutionsContentEntryTextIcon" />
                <div class="Home__solutionsContentEntryTextTitle">
                  Espace sportif
                </div>
                <div class="Home__solutionsContentEntryTextBody">
                  De la salle de réunion transformée en espace sportif
                  temporaire à l’espace de remise en forme avec machines et
                  appareils, nous vous accompagnons dans votre projet avec notre
                  cabinet d’architecture partenaire.
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="Home__company" id="company">
          <div class="Home__companyTitle">
            LE SPORT EN ENTREPRISE, <span>UN ENJEU HUMAIN</span>
          </div>
          <div class="Home__companyContent">
            <div class="Home__companyContentEntry">
              <Company1 class="Home__companyContentEntryImage" />
              <div class="Home__companyContentEntryTitle">
                Renforcer la cohésion
              </div>
            </div>
            <div class="Home__companyContentEntry">
              <Company2 class="Home__companyContentEntryImage" />
              <div class="Home__companyContentEntryTitle">Améliorer la QVT</div>
            </div>
            <div class="Home__companyContentEntry">
              <Company3 class="Home__companyContentEntryImage" />
              <div class="Home__companyContentEntryTitle">
                Booster la marque employeur et diffuser sa culture d'entreprise
              </div>
            </div>
          </div>
        </section>
        <section class="Home__flyer">
          <div class="Home__flyerContent">
            <div class="Home__flyerText">
              <div class="Home__flyerTitle">Téléchargez la brochure</div>
            </div>
            {/* TODO: On click download file */}
            <div class="Home__flyerButton">
              <a
                href="https://storage.googleapis.com/gymspot/public/Brochure%20Gymspot%20corporate.pdf"
                target="_blank"
              >
                <IconDownload />
              </a>
            </div>
          </div>
        </section>
      </div>
    </article>
  )
}
