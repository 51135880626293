import { h, render } from 'preact'
import './default.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'toastify-js/src/toastify.css'
import 'react-input-range/lib/css/index.css'
import 'react-material-stepper/dist/react-stepper.css'
import '@brainhubeu/react-carousel/lib/style.css'
import App from './app'
import { ParallaxProvider } from 'react-scroll-parallax'
import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment'
import 'react-widgets/dist/css/react-widgets.css'
import 'moment/locale/fr'
import 'react-calendar/dist/Calendar.css'

Moment.locale('fr')
momentLocalizer()

render(
  <ParallaxProvider>
    <App />
  </ParallaxProvider>,
  document.body
)
