import { h } from 'preact'
import './Layout.scss'
import Header from './Header'
import Footer from './Footer'
import Home from '../Home'

export default () => {
  return (
    <div class="Layout">
      <Header />
      <div class="Layout__content">
        <Home path="/" default />
      </div>
      <Footer />
    </div>
  )
}
